import payload from 'payload';
import type {
	OrderCartItems,
	User,
	Order,
	DiscountCode,
	Courseversion,
} from '../../../payload/payload-types';
import { extractId } from '../../../shared/extract-id';
import { Flatten } from '../../_utilities/typescript';
import {
	generateCartOrderItemPrices,
	generateSingleCartOrderItemPrice,
} from '../../../payload/utilities/generate-cart-order-item-prices';
export type CartItem = NonNullable<Flatten<OrderCartItems>>;

export type PaymentDetails = NonNullable<Order['paymentDetails']>;
export type PaymentDetail = NonNullable<Flatten<Order['paymentDetails']>>;

//type StateType = User['cart'] & Order['paymentDetails'];

export type StateType = User['cart'] & {
	paymentDetails?: PaymentDetails;
	cartError?: string; // eg not enough spots/quantity remaining
	activeOrder?: Order | null;
	hasInitialSync?: boolean;
};
type CartAction =
	| {
			payload: CartItem;
			type: 'ADD_ITEM';
	  }
	| {
			payload: StateType;
			type: 'MERGE_CART';
	  }
	| {
			payload: StateType;
			type: 'SET_CART';
	  }
	| {
			payload: CartItem;
			type: 'DELETE_ITEM';
	  }
	| {
			type: 'CLEAR_CART';
	  }
	| {
			type: 'SET_PAYMENT_DETAILS';
			payload: NonNullable<PaymentDetails>;
	  }
	| {
			type: 'SET_ACTIVE_ORDER';
			payload?: Order | null;
	  }
	| {
			type: 'SET_DISCOUNT_CODE_TEXT';
			payload?: string | null;
	  }
	| {
			type: 'CLEAR_DISCOUNT_CODE';
	  }
	| {
			type: 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT';
			payload: Order['chosenInstalmentPaymentsCount'];
	  }
	| {
			type: 'SET_INITIAL_SYNC';
			payload: boolean;
	  };

export const cartReducer = (state: StateType, action: CartAction): StateType => {
	switch (action.type) {
		case 'SET_CART': {
			return {
				...action.payload,
				cartItems: generateCartOrderItemPrices({
					items: action.payload.cartItems,
					discountCode: action.payload.discountCode,
					chosenInstalmentPaymentsCount: action.payload.chosenInstalmentPaymentsCount,
				}),
				hasInitialSync: true,
			};
		}

		case 'SET_INITIAL_SYNC': {
			return {
				...state,
				hasInitialSync: action.payload,
			};
		}

		case 'CLEAR_DISCOUNT_CODE': {
			return {
				...state,
				cartItems: generateCartOrderItemPrices({
					items: state.cartItems,
					discountCode: null,
					chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
				}),
				discountCode: null,
				discountCodeText: '',
			};
		}

		case 'MERGE_CART': {
			const { payload: incomingCart } = action;

			const existingItems = (state?.cartItems || []).filter(Boolean);
			const incomingItems = (incomingCart?.cartItems || []).filter(Boolean);

			const syncedItems = [...existingItems, ...incomingItems].reduce(
				(acc: CartItem[], cartItem) => {
					if (!cartItem.orderedCollection) {
						return acc;
					}

					// remove duplicates
					const itemId =
						typeof cartItem.orderedCollection.value === 'string'
							? cartItem.orderedCollection.value
							: cartItem?.orderedCollection?.value?.id;
					const itemType = cartItem.orderedCollection.relationTo;

					const isNotAvailableForPurchase =
						typeof cartItem?.orderedCollection.value === 'string' ||
						!cartItem?.orderedCollection.value?.isAvailableForPurchase;

					if (isNotAvailableForPurchase) {
						return acc;
					}

					const indexInAcc = acc.findIndex(_cartItem => {
						const _item = _cartItem?.orderedCollection;

						if (!_item) {
							return false;
						}

						return (
							(typeof _item.value === 'string'
								? _item.value === itemId
								: _item?.value.id === itemId) && itemType === _item.relationTo
						);
					});

					if (indexInAcc > -1) {
						const incomingItem = incomingItems.find(
							incoming =>
								extractId(incoming.orderedCollection.value) ===
								extractId(cartItem.orderedCollection.value),
						);

						let updates: Partial<CartItem> = {};
						if (incomingItem) {
							// make sure to add here incoming data that can change from server!!! eg discount code stuff
							updates = {
								priceWithoutVAT: incomingItem.priceWithoutVAT,
								price: incomingItem.price,
								VAT: incomingItem.VAT,
								discountedPriceWithoutVAT: incomingItem.discountedPriceWithoutVAT,
								discountedPrice: incomingItem.discountedPrice,
								discountedVAT: incomingItem.discountedVAT,
								discountCodeApplied: incomingItem.discountCodeApplied,
								discountedPriceReduction: incomingItem.discountedPriceReduction,
								discountedPriceReductionTotal:
									incomingItem.discountedPriceReductionTotal,
								discountedInstalmentPriceReduction:
									incomingItem.discountedInstalmentPriceReduction,
								discountCodePriceReduction: incomingItem.discountCodePriceReduction,
							};
						}

						acc[indexInAcc] = {
							...acc[indexInAcc],
							...updates,
							// customize the merge logic here, e.g.:
							// quantity: acc[indexInAcc].quantity + item.quantity
						};
					} else {
						acc.push(cartItem);
					}
					return acc;
				},
				[],
			);

			return {
				...state,
				discountCode: incomingCart?.discountCode,
				discountCodeText: incomingCart?.discountCodeText,
				chosenInstalmentPaymentsCount: incomingCart?.chosenInstalmentPaymentsCount,
				hasInitialSync: true,
				cartItems: syncedItems,
			};
		}

		case 'SET_PAYMENT_DETAILS': {
			const { payload: incomingPaymentDetails } = action;
			const updatedPaymentDetails = [...incomingPaymentDetails];

			return {
				...state,
				paymentDetails: updatedPaymentDetails,
			};
		}

		case 'SET_ACTIVE_ORDER': {
			const { payload: order } = action;

			return {
				...state,
				activeOrder: order,
			};
		}

		case 'ADD_ITEM': {
			// if the item is already in the cart, increase the quantity
			const { payload: incomingItem } = action;

			if (!incomingItem) {
				return state;
			}

			const indexInCart = findCartItemIndex(state, incomingItem);

			const cartItemsWithAddedItem = [...(state?.cartItems || [])];

			if (indexInCart === -1) {
				cartItemsWithAddedItem.push(incomingItem);
			}

			if (typeof indexInCart === 'number' && indexInCart > -1) {
				const incomingItemQuantity = incomingItem.quantity || 0;
				const existingItemQuantity = cartItemsWithAddedItem[indexInCart].quantity || 0;

				// replace existing quantity with incoming quantity
				cartItemsWithAddedItem[indexInCart] = {
					...cartItemsWithAddedItem[indexInCart],
					...incomingItem,
					quantity:
						(incomingItem.quantity || 0) > 0
							? incomingItemQuantity
							: existingItemQuantity,
				};
			}

			return {
				...state,
				cartItems: generateCartOrderItemPrices({
					items: cartItemsWithAddedItem,
					discountCode: state.discountCode,
					chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
				}),
			};
		}

		case 'DELETE_ITEM': {
			const { payload: incomingItem } = action;
			const withDeletedItem = { ...state };

			const indexInCart = findCartItemIndex(state, incomingItem);

			if (typeof indexInCart === 'number' && withDeletedItem.cartItems && indexInCart > -1) {
				withDeletedItem.cartItems.splice(indexInCart, 1);
			}

			// clear cart
			if (!withDeletedItem.cartItems?.length) {
				return {
					...state,
					discountCode: null,
					discountCodeText: null,
					cartItems: [],
					activeOrder: null,
					paymentDetails: [],
				};
			}

			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: withDeletedItem.cartItems,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
			});

			return {
				...state,
				cartItems: cartItemsWithPrices,
			};
		}

		case 'CLEAR_CART': {
			return {
				...state,
				discountCode: null,
				discountCodeText: null,
				cartItems: [],
				activeOrder: null,
				paymentDetails: [],
				chosenInstalmentPaymentsCount: null,
			};
		}

		case 'SET_DISCOUNT_CODE_TEXT': {
			return {
				...state,
				discountCodeText: action.payload,
			};
		}

		case 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT': {
			// recalculate because single instalment payment gets discounts and vice versa
			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: state.cartItems,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: action.payload,
			});

			return {
				...state,
				cartItems: cartItemsWithPrices,
				chosenInstalmentPaymentsCount: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

function findCartItemIndex(cart: StateType, incomingItem: CartItem) {
	const incomingItemType = incomingItem?.orderedCollection?.relationTo;

	if (!incomingItem) {
		return -1;
	}

	return cart?.cartItems?.findIndex(({ orderedCollection }) => {
		if (!orderedCollection) {
			return false;
		}

		const incomingItemId = extractId(incomingItem.orderedCollection.value);
		return (
			extractId(orderedCollection.value) === incomingItemId &&
			incomingItemType === orderedCollection.relationTo
		);
	});
}
