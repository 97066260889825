import { Courseversion, DiscountCode, OrderCartItems } from '../payload-types';
import { calculaceVat, calculatePrice } from './price';

type GenerateCartOrderTotalpricesParams = {
	items?: OrderCartItems;
	discountCode?: DiscountCode | null;
};
type TotalsType = {
	totalVat: number;
	totalPriceWithoutVAT: number;
	total: number;
	fullTotalVat: number;
	fullTotalPriceWithoutVAT: number;
	fullTotal: number;
	hasVAT: boolean;
	totalDiscountCodeReduction: number;
};

export function generateCartOrderTotalPrices({
	items,
	discountCode,
}: GenerateCartOrderTotalpricesParams): TotalsType {
	// let totalDiscountCodeReduction = 0;
	// if (discountCode && typeof discountCode !== 'string') {
	// 	if (discountCode.flatPrice && discountCode.discountType === 'flatValue') {
	// 		totalDiscountCodeReduction = discountCode.flatPrice;
	// 	}
	// }
	const defaultTotals = {
		totalVat: 0,
		totalPriceWithoutVAT: 0,
		total: 0,
		fullTotalVat: 0,
		fullTotalPriceWithoutVAT: 0,
		fullTotal: 0,
		hasVAT: false,
		totalDiscountCodeReduction: 0,
	};
	const totalPrices = items?.reduce((acc, item) => {
		if (!item.price || !item.quantity || !item.priceWithoutVAT) {
			return acc;
		}

		const quantity = item.quantity || 0;

		const fullItemVAT = item.VAT || 0;
		const fullItemPriceWithoutVAT = item.priceWithoutVAT;
		const fullItemPrice = item.price;

		const itemVAT = item?.discountedVAT || fullItemVAT || 0;

		const totalItemPrice = calculatePrice(
			fullItemPrice * quantity - (item.discountedPriceReductionTotal || 0),
		);
		const totalItemVAT = fullItemVAT > 0 ? calculaceVat(totalItemPrice * quantity) : 0;
		const totalItemPriceWithoutVAT = calculatePrice(totalItemPrice - totalItemVAT);

		const totalVat = calculatePrice(acc.totalVat + totalItemVAT);
		const totalPriceWithoutVAT = calculatePrice(
			acc.totalPriceWithoutVAT + totalItemPriceWithoutVAT,
		);
		const total = calculatePrice(acc.total + totalItemPrice);

		const fullTotalVat = calculatePrice(acc.fullTotalVat + fullItemVAT * quantity);
		const fullTotalPriceWithoutVAT = calculatePrice(
			acc.fullTotalPriceWithoutVAT + fullItemPriceWithoutVAT * quantity,
		);
		const fullTotal = calculatePrice(acc.fullTotal + fullItemPrice * quantity);

		const hasVAT = acc.hasVAT || Boolean(itemVAT && itemVAT > 0);

		return {
			totalVat,
			totalPriceWithoutVAT,
			total,
			// full prices dont consider any discounts
			fullTotalVat,
			fullTotalPriceWithoutVAT,
			fullTotal,
			hasVAT,
			totalDiscountCodeReduction: calculatePrice(
				acc.totalDiscountCodeReduction + (item?.discountCodePriceReduction || 0),
			),
		};
	}, defaultTotals);

	return totalPrices || defaultTotals;
}
