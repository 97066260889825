import { InstalmentPayments, Order } from '../payload-types';
import { calculatePrice } from './price';

type GetInitialAmountToPayParams = {
	total: number;
	instalmentPaymentsEnabled?: boolean;
	instalmentPayments?: InstalmentPayments;
};

// used if instalment payments are enabled on order
export function getInitialAmountToPay({
	total,
	instalmentPaymentsEnabled,
	instalmentPayments,
}: GetInitialAmountToPayParams): number {
	if (!total) {
		throw new Error('Cannot calculate amount to pay because total is missing');
	}
	if (instalmentPaymentsEnabled && instalmentPayments?.length) {
		const instalmentPaymentsTotal = instalmentPayments!.reduce((acc, item) => {
			return acc + item.amountToPay;
		}, 0);

		return calculatePrice(total! - instalmentPaymentsTotal);
	}

	return total!;
}
