import { InstalmentPayments, Order, SideMenu } from '../payload-types';

export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;
export type PaymentDetail = NonNullable<Flatten<Order['paymentDetails']>>;
export type InstalmentPayment = NonNullable<Flatten<InstalmentPayments>>;
export function isTruthy<T>(value?: T | undefined | null | false): value is T {
	return !!value;
}

// TODO id conditional, make sure to check before using this function
type CollectionWithId = {
	id: string;
};

export function extractId(value: CollectionWithId | string | undefined | null): string {
	if (!value) {
		throw new Error(`Cannot extract id from ${value}`);
	}

	return typeof value === 'string' ? value : value.id;
}

// import { RequestContext as OriginalRequestContext } from 'payload';

// declare module 'payload' {
// 	// Create a new interface that merges your additional fields with the original one
// 	export interface RequestContext extends OriginalRequestContext {
// 		isSidemenuConstructed?: boolean;

// 		// ...
// 	}
// }
