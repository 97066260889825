import { Courseversion, DiscountCode, OrderCartItems } from '../payload-types';
import { calculaceVat, calculatePrice } from './price';
import { extractId } from './typescript';

export const isDiscountCodeAppliableToCartItem = ({
	discountCode,
	courseversion,
	discountCodePriceReduction,
	quantity,
}: {
	discountCode?: DiscountCode | null | string;
	courseversion: Courseversion;
	discountCodePriceReduction: number;
	quantity: number;
}): boolean => {
	let isDiscountCodeAppliable = false;

	if (!discountCode) {
		return false;
	}

	if (typeof discountCode === 'string') {
		console.warn('isDiscountCodeAppliableToCartItem: discountCode is string: ', discountCode);

		return false;
	}

	if (discountCode.affiliationType === 'all') {
		isDiscountCodeAppliable = true;
	}

	const discountCourseversionIds = discountCode?.courseversions?.map(extractId) || [];

	if (discountCode.affiliationType === 'allWithExclusions' && discountCourseversionIds.length) {
		isDiscountCodeAppliable = !discountCourseversionIds.includes(courseversion.id);
	}

	if (discountCode.affiliationType === 'chosen' && discountCourseversionIds.length) {
		isDiscountCodeAppliable = discountCourseversionIds.includes(courseversion.id);
	}

	if (isDiscountCodeAppliable) {
		// lets see if courseversion has discount and it's cheaper overall
		// courseversion has no discountedPrice
		if (!courseversion.discountedPrice) {
			return true;
		}

		const discountedPriceWithDiscountCode =
			quantity * courseversion.price - discountCodePriceReduction;
		const totalCourseversionDiscountedCost = quantity * courseversion.discountedPrice;

		// business logic: only apply if total price reduction is greater than normal price reduction
		return totalCourseversionDiscountedCost > discountedPriceWithDiscountCode;
	}

	return isDiscountCodeAppliable;
};

export const getDiscountCodePriceReduction = ({
	discountCode,
	courseversion,
	quantity,
}: {
	discountCode?: DiscountCode | null | string;
	courseversion: Courseversion;
	quantity: number;
}): number => {
	// apply discountcode discount to price
	// check of price is not already discounted and lower than the code

	// no need to check for discount code active status

	let discountCodePriceReduction = 0;

	if (!discountCode) {
		return discountCodePriceReduction;
	}

	if (typeof discountCode === 'string') {
		console.warn('getDiscountCodePriceReduction: discountCode is string: ', discountCode);

		return discountCodePriceReduction;
	}

	// let discountedCodePriceWithoutVAT = 0;
	// let discountedCodeVAT = 0;
	// let discountedCodePrice = 0;

	if (discountCode.discountType === 'percentage' && discountCode.percentage) {
		const singlePricereduction = calculatePrice(
			courseversion.price * (discountCode.percentage / 100),
		);
		discountCodePriceReduction = calculatePrice(singlePricereduction * quantity);
	} else if (discountCode.discountType === 'flatValue' && discountCode.flatPrice) {
		discountCodePriceReduction = discountCode.flatPrice;
	}

	const isAppliable = isDiscountCodeAppliableToCartItem({
		discountCode,
		courseversion,
		discountCodePriceReduction,
		quantity,
	});

	if (isAppliable) {
		return discountCodePriceReduction;
	}
	// const canAddDiscountCode =
	// 	!discountedPrice ||
	// 	Boolean(
	// 		discountedPrice &&
	// 			discountedPrice * quantity >
	// 				courseversion.price * quantity -
	// 					(courseversion.price - discountedCodePrice),
	// 	);

	// // only apply if discount code price is lower than discounted courseversion (taking into account quantity), if exists
	// // make sure price cannot go under 1 eur just in case..
	// if (canAddDiscountCode && discountedCodePrice > 0) {
	// 	discountedPriceWithoutVAT = discountedCodePriceWithoutVAT;
	// 	discountedVAT = discountedCodeVAT;
	// 	discountedPrice = discountedCodePrice;
	// 	discountCodeApplied = true;
	// }

	return 0;
};
