import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';
import { InstalmentPayments, OrderCartItems } from '../payload-types';
import { PAYMENT_DEADLINE_DAYS_COUNT, DB_DATE_FORMAT } from './config';
import { calculatePrice } from './price';
import { getInitialAmountToPay } from './get-initial-amount-to-pay';

dayjs.extend(dayjsBusinessDays);

type GenerateInstalmentPaymentsCalendarParams = {
	items?: OrderCartItems;
	shares: number;
	totalPrice: number;
	shouldAddFirstPaymentToCalendar?: boolean;
};

export function generateInstalmentPaymentsCalendar({
	items,
	shares,
	totalPrice,
	shouldAddFirstPaymentToCalendar,
}: GenerateInstalmentPaymentsCalendarParams): InstalmentPayments {
	const instalmentPayments: InstalmentPayments = [];

	if (!items?.length) {
		return instalmentPayments;
	}

	const orderedItem = items[0];

	const amountToPay = calculatePrice(totalPrice / shares);

	const courseversion = orderedItem.orderedCollection.value;

	if (typeof courseversion === 'string') {
		throw new Error('generateInstalmentPaymentsCalendar: Bad orderedItem courseversion depth');
	}
	const courseversionCalendar = courseversion.instalmentPaymentsCalendars!.find(
		calendar => calendar.instalmentShareCount === shares,
	);

	if (!courseversionCalendar) {
		throw new Error(
			`Could not find courseversion calendar with ${shares} shares, courseversionId: ${courseversion.id}`,
		);
	}
	// remove firstPayment
	shares = shares - 1;
	const instalmentCount = Array.from({ length: shares }, (_, i) => i + 1);

	instalmentCount.forEach((share, i) => {
		if (!courseversionCalendar.instalmentPaymentsCalendar?.[i]) {
			throw new Error(
				`Could not find courseversion calendar at share ${share}, courseversionId: ${courseversion.id}`,
			);
		}
		const calendarItem = {
			deadlineDate:
				courseversionCalendar.instalmentPaymentsCalendar[i].instalmentPaymentDeadlineDate,
			amountToPay,
		};

		instalmentPayments.push(calendarItem);
	});

	if (shouldAddFirstPaymentToCalendar) {
		const firstPayment = {
			deadlineDate: dayjs()
				.businessDaysAdd(PAYMENT_DEADLINE_DAYS_COUNT)
				.format(DB_DATE_FORMAT),
			amountToPay: getInitialAmountToPay({
				total: totalPrice,
				instalmentPaymentsEnabled: true,
				instalmentPayments,
			}),
		};

		instalmentPayments.unshift(firstPayment);
	}

	return instalmentPayments;
}
